import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCard,[_c(VCardTitle,[_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.store.getters.tablets,"search":_vm.search,"item-key":"id","loading":_vm.tableLoading,"footer-props":_vm.footerProps,"loading-text":"Loading tablets. Please wait ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('confirmation-dialog',{ref:"deleteTablet"}),_c('accept-tablet-dialog',{ref:"acceptTablet"})]},proxy:true},{key:`item.status`,fn:function({ item }){return [_c(VIcon,[_vm._v(" "+_vm._s(_vm.statusIcon(item))+" ")])]}},{key:`item.lastSeen`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatTime(item))+" ")]}},{key:`item.actions`,fn:function({ item }){return [(item.verified)?_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")]):_c(VRow,[_c(VBtn,{staticClass:"ma-2",attrs:{"outlined":"","color":"success","small":""},on:{"click":function($event){return _vm.acceptItem(item)}}},[_vm._v(" Accept ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"outlined":"","color":"error","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" Decline ")])],1)]}}],null,true)})],1)],1),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("clear")])],1)]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }