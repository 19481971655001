

import Vue from "vue";
import { ITablet, EMPTY_TABLET } from "@common/station";

type ResolveFunction = (value: string) => void;
type RejectFunction = (value: Error) => void;

export default Vue.extend({
	name: "AcceptTabletDialog",
	data: () => ({
		dialog: false as boolean,
		resolve: null as ResolveFunction|null,
		reject: null as RejectFunction|null,
		tabletInfos: { ...EMPTY_TABLET } as ITablet,
		tabletPin: "" as string
	}),
	methods: {
		open (tabletInfos: ITablet) {
			this.dialog = true;
			this.tabletInfos = { ...tabletInfos };
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		clearPromise () {
			this.resolve = null;
			this.reject = null;
		},
		clearVars () {
			this.tabletInfos = { ...EMPTY_TABLET };
			this.tabletPin = "";
		},
		agree () {
			if (this.resolve) {
				this.resolve(this.tabletPin);
			}
			this.dialog = false;
			this.clearPromise();
			this.clearVars();
		},
		cancel () {
			if (this.reject) {
				this.reject(new Error("User Cancel"));
			}
			this.dialog = false;
			this.clearPromise();
			this.clearVars();
		},
	},
});
