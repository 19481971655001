import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{style:({ zIndex: 200 }),attrs:{"max-width":"500"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.agree.apply(null, arguments)}]},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"success","dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Accept tablet")])],1),_c(VCardText,{staticClass:"pa-4 text-subtitle-2"},[_c('h4',[_vm._v(" "+_vm._s(`Accept tablet from station ${_vm.tabletInfos.stationName}.`)+" ")]),_c('h4',[_vm._v(" Please insert the PIN from the tablet display! ")]),_c(VTextField,{attrs:{"label":"Tablet PIN","clearable":""},model:{value:(_vm.tabletPin),callback:function ($$v) {_vm.tabletPin=$$v},expression:"tabletPin"}})],1),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"success"},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("done")]),_vm._v("Accept ")],1),_c(VBtn,{attrs:{"depressed":"","color":"error"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("cancel")]),_vm._v("Cancel ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }