
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment-timezone";
import ConfirmationDialog, { ConfirmationDialogOptions } from "@/components/common/ConfirmationDialog.vue";
import AcceptTabletDialog from "@/components/tablets/AcceptTabletDialog.vue";
import { ITablet, ITabletDeleteRes } from "@common/station";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

export default Vue.extend({
	name: "Tablets",
	components: {
		"confirmation-dialog": ConfirmationDialog,
		"accept-tablet-dialog": AcceptTabletDialog
	},
	data: () => {
		return {
			search: "" as string,
			tableLoading: false as boolean,
			headers: [
				new TableHeader("Station ID", "start", true, "stationId"),
				new TableHeader("Station Name", "start", true, "stationName"),
				new TableHeader("Status", "start", false, "status"),
				new TableHeader("Last Seen", "start", false, "lastSeen"),
				new TableHeader("Actions", "start", false, "actions")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			snackbar: {
				show: false as boolean,
				text: "" as string,
				color: "primary" as string,
			}
		};
	},
	created: async function () {
		this.store.dispatch.changeAppTitle("Tablets");
		if (this.store.getters.tablets.length === 0) {
			await this.store.dispatch.fetchTablets();
		}
	},
	computed: {
		statusIcon () {
			return (item: ITablet) => {
				if (moment().diff(moment(item.lastSeen), "minutes") <= 10) {
					return "done";
				} else {
					return "close";
				}
			};
		},
		formatTime () {
			return (item: ITablet) => {
				return moment(item.lastSeen).format("HH:mm DD/MM/yy");
			};
		}
	},
	methods: {
		async deleteItem (item: ITablet) {
			try {
				const dialogTitle = "Tablet delete";
				const dialogMessage = `Are you sure you want to delete tablet from station "${item.stationName}"?`;
				const dialogOptions: ConfirmationDialogOptions = {
					color: "error",
					width: 400,
					agree: {
						text: "Delete",
						color: "error",
						icon: "delete_forever",
					},
					cancel: {
						text: "Cancel",
						color: "secondary",
						icon: "close",
					}
				};
				const deleteConfirmation = await (this.$refs.deleteTablet as InstanceType<typeof ConfirmationDialog>).open(
					dialogTitle,
					dialogMessage,
					dialogOptions
				);
				if (deleteConfirmation) {
					const data: ITablet = item;
					const options: AxiosRequestConfig = {
						method: "POST",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						data,
						url: `${this.store.getters.serverURL}/tablet/delete`,
					};
					const res: AxiosResponse<IServerRES<ITabletDeleteRes>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						if (res.data.payload.succeeded) {
							this.store.dispatch.deleteTablet(item);
						}

						this.snackbar = {
							show: true,
							color: "success",
							text: "Tablet was succesfully deleted.",
						};
					}
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		},
		async acceptItem (item: ITablet) {
			try {
				const data: ITablet = { ...item };
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/tablet/confirm`,
				};
				data.tabletPin = await (this.$refs.acceptTablet as InstanceType<typeof AcceptTabletDialog>).open(item) as string;
				const res: AxiosResponse<IServerRES<ITabletDeleteRes>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					if (res.data.payload.succeeded) {
						item.verified = true;
						this.snackbar = {
							text: "Tables was successfully verified!",
							color: "success",
							show: true,
						};
					} else {
						this.snackbar = {
							text: "Tablet PIN is INVALID!",
							color: "error",
							show: true,
						};
					}
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		}
	}
});
